<template>
  <div class="my-8">
    <h1 class="text-xl font-bold">Transactions</h1>

    <div class="flex flex-col mt-4">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Customer
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Branch
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Staff
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Units
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(transaction, i) in transactions.data" :key="i">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ transaction.customer }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ transaction.meter_serial }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ transaction.branch }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ transaction.staff }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ transaction.units }}
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-bold text-gray-900">
                      GMD {{ transaction.amount }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span v-if="transaction.status_val == 'INITIATED'" class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-blue-100
                        text-blue-800
                      ">
                      INITIATED
                    </span>
                    <span v-if="transaction.status_val == 'PROCESSED'" class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      ">
                      PROCESSED
                    </span>
                    <span v-if="transaction.status_val == 'PROCESSING'" class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-yellow-100
                        text-yellow-800
                      ">
                      PROCESSING
                    </span>
                    <span v-if="transaction.status_val == 'FAILED'" class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-red-100
                        text-red-800
                      ">
                      FAILED
                    </span>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a href="#" class="text-indigo-600 hover:text-indigo-900">View</a>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <div class="border-t" v-if="transactions">
              <pagination :data="transactions" :midSize="2" @clicked="loadData">
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "@/components/Pagination";

export default {
  props: ["data"],
  components: { Pagination },

  data: () => ({
    transactions: [],
    searching: false,
    filter: "",
  }),

  created() {
    var _this = this;
    _this.loadData(1);
  },

  methods: {
    //
    async loadData(pageNo, filter) {
      // Submit the form

      console.log("STAFF", this.data);

      if (filter) {
        // filter = filter;
        this.searching = true;
        // this.isLoading = false;
      } else {
        filter = "";
        this.searching = false;
        // this.isLoading = false;
      }

      // this.isLoading = true;
      try {
        const { data } = await axios.get(
          `/chapman/branch/transactions?page=${pageNo}&filter=&branch_id=&staff_id=${this.data.user_id}&date=&status=`
        );

        console.log(data);

        this.transactions = data;
        this.searching = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },
  },
};
</script>
