<template>
  <div class="">
    <div class="flex">
      <div v-if="staff" class="container mx-auto">
        <div class="flex">
          <div class="w-1/4 pt-6">
            <div class="mb-4">
              <div class="text-3xl font-medium text-grey-darkest">
                {{ staff.name }}
              </div>
              <div class="text-xl text-grey-dark font-light">
                {{ staff.branch }}
              </div>
            </div>

            <div class="flex items-center text-left py-4 mb-4 border-b">
              <div class="flex items-center mr-2">
                <svg
                  class="fill-current text-grey-dark w-4 h-4"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"
                  />
                </svg>
              </div>
              <div>
                <a href="#" class="no-underline text-sm">
                  {{ staff.email }} / {{ staff.contact_phone }}
                </a>
              </div>
            </div>
          </div>
          <div class="w-3/4 ml-6 mt-2">
            <div
              class="
                flex
                items-center
                font-thin
                text-grey-dark text-sm
                border-b
              "
            >
              <button
                @click="current = 'overview'"
                :class="{ 'border-blue-500 border-b-2': current == 'overview' }"
                class="p-4 font-normal text-grey-darkest"
              >
                Overview
              </button>
              <button
                @click="current = 'transactions'"
                :class="{
                  'border-blue-500 border-b-2': current == 'transactions',
                }"
                class="
                  p-4
                  flex
                  items-center
                  font-normal
                  hover:text-blue-800 hover:border-b-2 hover:border-blue-500
                "
              >
                <div>Transactions</div>
                <!-- <div class="rounded-lg bg-grey-light text-xs ml-1 p-px">41</div> -->
              </button>
              <button
                @click="current = 'worksheets'"
                :class="{
                  'border-blue-500 border-b-2': current == 'worksheets',
                }"
                class="
                  p-4
                  flex
                  items-center
                  font-normal
                  hover:text-blue-800 hover:border-b-2 hover:border-blue-500
                "
              >
                <div>Worksheets</div>
              </button>

              <div
                @click="current = 'logs'"
                :class="{
                  'border-blue-500 border-b-2': current == 'logs',
                }"
                class="
                  p-4
                  flex
                  items-center
                  font-normal
                  hover:text-blue-800 hover:border-b-2 hover:border-blue-500
                "
              >
                <div>Activities</div>
              </div>
            </div>
            <div class="flex m items-center"></div>
            <overview v-if="current === 'overview'" :data="staff" />
            <transactions v-if="current === 'transactions'" :data="staff" />
            <worksheet
              v-if="current === 'worksheets'"
              :userData="user"
              :staff="staff"
            />
            <Log v-if="current === 'logs'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Overview from "./View/Overview";
import Transactions from "./View/Transactions.vue";
import Worksheet from "./View/Worksheet.vue";
import Log from "./View/Log.vue";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",

  components: { Overview, Transactions, Worksheet, Log },
  layout: "default",

  data: () => ({
    isLoading: false,
    staff: null,

    current: "overview",
  }),

  computed: mapGetters({
    user: "auth/user",
  }),

  mounted() {
    var _this = this;
    _this.loadData(this.user.data.staff.staff_id);
  },

  methods: {
    async loadData(id) {
      //Get Data

      this.isLoading = true;
      try {
        const { data } = await axios.get(`/staffs/${id}`);

        this.isLoading = false;
        this.staff = data.data;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },
  },
};
</script>