<template>
  <div class="my-8">
    <div class="flex justify-between">
      <h1 class="text-xl font-bold">Worksheet</h1>
      <router-link
        v-if="userData.data.id == staff.user_id"
        class="border px-6 py-2 rounded-lg text-sm flex justify-center items-center hover:bg-gray-100"
        to="/generate_worksheet"
        >Generate Worksheet</router-link
      >
    </div>

    <div class="flex flex-col mt-4">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    #ID
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Total Deposits
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Total Sales
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Cash in hand
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Print</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(worksheet, i) in worksheets.data" :key="i">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      {{ worksheet.ws_id }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      {{ worksheet.date }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      GMD <b>{{ worksheet.total_deposits }}</b>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      GMD <b>{{ worksheet.total_sales }}</b>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      GMD <b>{{ worksheet.cash_in_hand }}</b>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      class="text-indigo-600 hover:text-indigo-900"
                      @click="print(worksheet)"
                    >
                      Print
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <div class="border-t" v-if="worksheets">
              <pagination :data="worksheets" :midSize="2" @clicked="loadData">
              </pagination>
            </div>
          </div>
        </div>

        <worksheet-print :data="selectedPrint" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import FormatPrice from "@/mixins/FormatPrice";
import Pagination from "@/components/Pagination";
import JSPM from "../../../../../public/JSPrintManager";
import WorksheetPrint from "../../../../components/WorksheetPrint.vue";

export default {
  components: { Pagination, WorksheetPrint },
  props: ["userData", "staff"],

  mixins: [FormatPrice],

  data: () => ({
    worksheets: [],
    searching: false,
    filter: "",

    print2default: true,

    selectedPrint: null,
  }),

  created() {
    var _this = this;
    _this.loadData(1);
  },

  methods: {
    print(worksheet) {
      console.log("Printing Worksheet", worksheet);
      if (this.selected_printer === "" && !this.print2default) {
        alert("You must select a printer");
        return;
      }

      var cpj = new JSPM.ClientPrintJob();

      if (this.print2default) {
        cpj.clientPrinter = new JSPM.DefaultPrinter();
      } else {
        cpj.clientPrinter = new JSPM.InstalledPrinter(this.selected_printer);
      }

      var dateCreated = moment(worksheet.created_at).format("Do MMM, YYYY, h:mm:ss a");

      //Set content to print...
      //Create ESP/POS commands for sample label
      var esc = "\x1B"; //ESC byte in hex notation
      var newLine = "\x0A"; //LF byte in hex notation
      var fullCut = esc + "m";

      var cmds = esc + "@"; //Initializes the printer (ESC @)
      cmds += esc + "!" + "\x38"; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
      cmds += "WORKSHEET"; //text to print
      cmds += newLine;
      cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)

      cmds += newLine + newLine;

      cmds += `ID .:                ${worksheet.ws_id}`;
      cmds += newLine;
      cmds += `Date .:              ${worksheet.date}`;
      cmds += newLine;
      cmds += `Staff .:             ${worksheet.staff}`;
      cmds += newLine;
      cmds += `Branch .:            ${worksheet.branch}`;
      cmds += newLine;
      cmds += "--------------------------------------------";
      cmds += newLine;
      cmds += `TOTAL SALES .:       GMD ${this.formatPrice(worksheet.total_sales)}`;
      cmds += newLine;
      cmds += `TOTAL DEPOSITS .:    GMD ${this.formatPrice(worksheet.total_deposits)}`;

      cmds += newLine;
      cmds += `TOTAL.:    GMD ${this.formatPrice(
        worksheet.total_deposits + worksheet.total_sales
      )}`;
      cmds += newLine;
      cmds += esc + "!" + "\x18"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
      cmds += `CASH IN HAND .:      GMD ${this.formatPrice(worksheet.cash_in_hand)}`;
      cmds += esc + "!" + "\x00"; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
      cmds += newLine;
      cmds += "--------------------------------------------";

      cmds += newLine + newLine;
      cmds += newLine + newLine;
      cmds += newLine;

      cmds += "--------------------------------------------";
      cmds += newLine;
      cmds += `Signature (${worksheet.staff})`;
      cmds += newLine;
      cmds += `Created .:  ${dateCreated}`;
      cmds += newLine + newLine;
      cmds += newLine + newLine;

      cmds += fullCut;

      cpj.printerCommands = cmds;
      //Send print job to printer!
      cpj.sendToClient();
    },
    //
    async loadData(pageNo, filter) {
      // Submit the form

      if (filter) {
        // filter = filter;
        this.searching = true;
        // this.isLoading = false;
      } else {
        filter = "";
        this.searching = false;
        // this.isLoading = false;
      }

      let userId = "";

      if (this.$route.params.id) {
        userId = this.$route.params.id;
      } else {
        userId = this.staff.staff_id;
      }

      // this.isLoading = true;
      try {
        const { data } = await axios.get(
          `/showMyWorksheet/${userId}/show?page=${pageNo}&filter=${filter}`
        );

        console.log(data);

        this.worksheets = data;
        this.searching = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },

    onInit: function () {
      console.log("IN INIT", JSPM);
      var _this = this;
      JSPM.JSPrintManager.auto_reconnect = true;
      JSPM.JSPrintManager.start();
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        _this.getPrinters().then((p) => {
          _this.printers = p;
          _this.$nextTick();
        });
      };
    },

    getPrinters: function () {
      return new Promise((ok, err) => {
        let printers = [];
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters()
            .then(function (myPrinters) {
              printers = myPrinters;
              console.log(printers);
              ok(printers);
            })
            .catch((e) => err(e));
        } else {
          console.warn("JSPM WS not open");
          ok(printers);
        }
      });
    },
  },

  mounted() {
    this.onInit();
  },
};
</script>
