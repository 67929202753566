<template>
  <div id="section-to-print">
    <div class="printable_header">
      <h1 class="__title">WORKSHEET</h1>
    </div>

    <div class="printable_header_2">
      <table class="">
        <tr class="px-2">
          <td>ID</td>
          <td class="text-right font-bold">{{ data.ws_id }}</td>
        </tr>

        <tr>
          <td>Date</td>
          <td class="text-right font-bold">{{ data.date }}</td>
        </tr>

        <tr>
          <td>Staff</td>
          <td class="text-right font-bold">{{ data.staff }}</td>
        </tr>

        <tr>
          <td>Branch</td>
          <td class="text-right font-bold">{{ data.branch }}</td>
        </tr>
      </table>
    </div>

    <div class="printable_content">
      <table>
        <tr>
          <td>TOTAL SALES</td>
          <td class="text-right">
            GMD <span class="font-bold">{{ data.total_sales }}</span>
          </td>
        </tr>

        <tr>
          <td>TOTAL DEPOSITS</td>
          <td class="text-right">
            GMD <span class="font-bold">{{ data.total_deposits }}</span>
          </td>
        </tr>

        <tr>
          <!-- <td>CASH IN HAND</td>
          <td class="text-right">{{ data.staff }}</td> -->
        </tr>

        <tr class="__total">
          <td>CASH IN HAND</td>
          <td class="text-right">
            GMD <span class="font-bold">{{ data.cash_in_hand }}</span>
          </td>
        </tr>
      </table>

      <p class="text-gray-700 mt-2">
        Created: {{ data.created_at | moment("Do MMM, YYYY, h:mm:ss a") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style>
#section-to-print,
#section-to-print * {
  visibility: hidden;
}

@media print {
  .printable_header {
    text-align: center;
  }

  .__total {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-style: dashed;
    /* margin-top: 15px; */
  }
  .printable_content {
  }
  .printable_header {
    /* padding: 10px 0px; */
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-style: dashed;
  }

  .printable_header_2 {
    /* padding: 10px 0px; */
    /* border-top: 1px solid #000; */
    border-bottom: 1px solid #000;
    border-style: dashed;
  }

  .__title {
    font-size: 30px;
    font-weight: 800;
  }

  body * {
    visibility: hidden;
  }

  #hide-to-print #hide-to-print * {
    visibility: hidden;
  }

  .__hide__to_print {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    /* width: 100mm; */
    /* background: #333; */
    background: rgb(255, 255, 255);
    width: 100mm;
    padding: 10px 0px;
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>