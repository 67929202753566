<template>
  <div class="my-8">
    <div class="flex justify-between">
      <h1 class="text-xl font-bold">Activities</h1>
    </div>

    <div class="flex flex-col mt-4">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Subject
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Date / Time
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    CLIENT
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(log, i) in logs" :key="i">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      {{ log.subject }}
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <div class="text-sm font-medium text-gray-900">
                      {{
                        log.created_at | moment("dddd, MMMM Do YYYY, h:mm:ss a")
                      }}
                    </div>
                  </td>

                  <td class="px-6 py-4">
                    <div class="text-sm text-gray-900">
                      {{ log.agent }}
                    </div>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <div class="border-t" v-if="logs">
              <pagination :data="logs" :midSize="2" @clicked="loadData">
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import moment from 'vue-moment';
import Pagination from "@/components/Pagination";

export default {
  props: ["userData", "staff", "data"],
  components: { Pagination },

  data: () => ({
    logs: [],
    searching: false,
    filter: "",

    selectedPrint: null,
  }),

  created() {
    var _this = this;
    _this.loadData(1);
  },

  methods: {
    print(data) {
      this.selectedPrint = data;
      setTimeout(() => {
        window.print();
      }, 1000);
    },
    //
    async loadData(pageNo, filter) {
      // Submit the form

      // console.log("staff", this.userData.id);

      const userId = this.data.user_id;

      if (filter) {
        // filter = filter;
        this.searching = true;
        // this.isLoading = false;
      } else {
        filter = "";
        this.searching = false;
        // this.isLoading = false;
      }

      try {
        const { data } = await axios.get(
          `/mylogs?page=${pageNo}&userId=${userId}&filter=${filter}`
        );

        console.log(data);

        this.logs = data;
        this.searching = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },
  },
};
</script>